import * as React from 'react';
import {Helmet} from 'react-helmet';
import moment from 'moment';

import Layout from '@components/layouts/layout';
import Navigation from '@components/navigation/navigation';
import Footer from '@components/footer/footer';

import {Container, Row, Col} from 'react-bootstrap';
import NewsGrid from '@components/news/grid';

const AktualnosciPage = ({pageContext}) => {
  const filterNews = news => {
    return news.filter(n => moment().valueOf() > n.dateTimestamp);
  };

  const news = filterNews(pageContext.news);

  const newsListEmpty = () => {
    return (
      <>
        <h5 className="text-center">Brak elementów do wyświetlenia</h5>
        <div className="py-xl" />
      </>
    );
  };

  return (
    <Layout>
      <Helmet>
        <title>Aktualności – Studenckie Koła Naukowe AGH</title>
        <meta property="og:title" content={`Aktualności – Studenckie Koła Naukowe AGH`} />
      </Helmet>

      <Navigation customPagesNav={pageContext.customPagesNav} />
      <div className="news-page">
        <Container>
          <Row>
            <Col>
              <div className="news-page__header">
                <h1>Aktualności</h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {news.length > 0 && <NewsGrid news={news} />}
              {news.length === 0 && newsListEmpty()}
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </Layout>
  );
};

export default AktualnosciPage;
